import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../common/components/Pagination';
import PropTypes from 'prop-types';
import TemplatesToolTip from '../../common/components/tooltips/TemplatesToolTip';
import { Link, useParams } from 'react-router-dom';
import { routes } from '../../config';
import { isEmpty, forEach, filter, includes, startCase, toLower, cloneDeep } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { CloseSharp } from '@flk-mui-icons';
import axios from 'axios';
import * as lease from '../../actions/lease';
import * as agency from '../../actions/agency';
import * as dashboard from '../../actions/dashboard';
import * as connections from '../../actions/connections';
import { updateTeamMemberViewing, updateTeamMemberViewingState } from '../../actions/user';

import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING
} from '../../config';
import AgreementStatusToolbar from '../../components/dashboard/agreementList/AgreementStatusToolbar';
import AgreementTable from '../../components/dashboard/agreementList/AgreementTable';
import EmptyState from '../../components/dashboard/agreementList/EmptyState';
import MultiSelect from '../../common/components/MultiSelect';
import { getAgentSignature } from '../../actions/completion';
import { isAdmin } from '../../utils/userUtils';
import { isLeaseTypePm, isLeaseTypeCommercialPm, mapLeaseStatusToCategory } from '../../utils/agreementUtils';
import Button from '../../common/components/Button';
import styles from './AgreementList.module.scss';
import Icon, { Icons } from '../../common/components/Icon';
import {
    HolidayLettingDashboard,
    SalesDashboard,
    PropertyManagementDashboard,
    CommercialPropertyManagementDashboard
} from '@flkitover/common-dashboard-ui';

import {
    getHasAgreements,
    getIsPending,
    getHasInitialAgreementListRequested,
    getPageNav,
    getSortOptions,
    getAgreementList,
    getRefreshPage,
    getForceRefreshPage,
    getSocketUpdateDetails
} from '../../selectors/dashboard/agreementList';
import { getTeam } from '../../selectors/agency';
import {
    getIsTeamMemberSelectionPending,
    getIsTeamMemberSelectionSuccess,
    getSavedTeamMemberSelection,
    getUser,
    getUserInfo,
    getUserRole,
    isUserRoleAdmin,
    getAgencyId
} from '../../selectors/user';
import { ReactComponent as ResidentialTenancyIcon } from '../../../assets/images/icons/tenancy.svg';
import { ReactComponent as PropertyManagementIcon } from '../../../assets/images/icons/case.svg';
import { ReactComponent as SalesIcon } from '../../../assets/images/icons/sales.svg';
import CommercialLeaseIcon from '@flk-mui-icons/BusinessSharp';

import { useAgreementUpdate } from '../../hooks/useAgreementUpdate';
import {
    resetRefreshPage,
    socketUpdateFullAgreement,
    socketUpdatePageRefresh,
    socketUpdatePartialAgreement,
    refreshPageOnUpdate,
    changeIsPendingStatus
} from '../../actions/dashboard';
import { useAgreementAdded } from '../../hooks/useAgreementAdded';
import { useAgreementRemoved } from '../../hooks/useAgreementRemoved';
import Dashboard from '../../common/components/dashboard/Dashboard';
import DashboardV2 from '../../common/components/dashboard/DashboardV2';
import DashboardHeader from '../../common/components/dashboard/DashboardHeader';
import { useOpenAgreement } from '../../hooks/useOpenAgreement';
import { defaultPage, defaultRecordsPerPage } from '../../config';
import { useLegacyDashboardActions } from './LegacyDashboardActions';

const AGENCY_STREAM_MESSAGE_RECEIVED = 'agency-stream-message-received';

const AgreementList = () => {
    const dispatch = useDispatch();
    const { openModalInfoAgreement } = useOpenAgreement();
    const history = useHistory();

    const [selected, setSelected] = useState([]);
    const [savedTeamMembers, setSavedTeamMembers] = useState([]);
    const [isClearRenewalbuttonDisabled, setIsClearRenewalbuttonDisabled] = useState(false);

    const hasAgreements = useSelector(getHasAgreements);
    const isPending = useSelector(getIsPending);
    const { leaseStatus, leaseType } = useParams();
    const leaseTypeFromDB = startCase(toLower(leaseType.replace(/_/g, ' ')));
    const pageNav = useSelector(getPageNav);
    const sortOptions = useSelector(getSortOptions);
    const userInfo = useSelector(getUserInfo);
    const user = useSelector(getUser);
    const isUserAdmin = useSelector(isUserRoleAdmin);
    const userRole = useSelector(getUserRole);
    const agencyId = useSelector(getAgencyId);

    const agreementList = useSelector(getAgreementList);
    const refreshPage = useSelector(getRefreshPage);
    const forceRefreshPage = useSelector(getForceRefreshPage);
    const socketUpdateDetails = useSelector(getSocketUpdateDetails);

    const hasInitialAgreementListRequested = useSelector(getHasInitialAgreementListRequested);
    const team = useSelector(getTeam);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const isTeamMemberSelectionSuccess = useSelector(getIsTeamMemberSelectionSuccess);
    const isTeamMemberSelectionPending = useSelector(getIsTeamMemberSelectionPending);

    const { performLegacyAction } = useLegacyDashboardActions();

    const useDashboardV2 =
        leaseType === LEASE_TYPE_HOLIDAY_LETTING ||
        leaseType === LEASE_TYPE_SALES ||
        leaseType === LEASE_TYPE_PROPERTY_MANAGEMENT ||
        leaseType === LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT;

    /**
     * Hook to listen to updates of agreements within an agency
     */
    useAgreementUpdate(user.agency, item => {
        if (item.data.refreshType && item.data.refreshType === 'partial') {
            dispatch(socketUpdatePartialAgreement(item.data));
        } else if (item.data.refreshType && item.data.refreshType === 'document') {
            dispatch(socketUpdateFullAgreement(item.data));
        } else if (item.data.refreshType && item.data.refreshType === 'page') {
            dispatch(socketUpdatePageRefresh(item.data, true));
        }

        const { leaseType, id } = item.data;
        const event = new CustomEvent(AGENCY_STREAM_MESSAGE_RECEIVED, {
            detail: {
                type: 'agreement-legacy-update',
                agreementType: leaseType,
                agreementId: id
            }
        });

        document.dispatchEvent(event);
    });

    /**
     * Hook to listen when an agreement was added to an agency
     */
    useAgreementAdded(user.agency, item => {
        if (item.data.refreshType && item.data.refreshType === 'page') {
            dispatch(socketUpdatePageRefresh(item.data, true));
        }

        const event = new CustomEvent(AGENCY_STREAM_MESSAGE_RECEIVED, {
            detail: {
                type: 'agreement-created',
                agreementType: item.data.leaseType
            }
        });

        document.dispatchEvent(event);
    });

    /**
     * Hook to listen when an agreement was removed from an agency
     */
    useAgreementRemoved(user.agency, item => {
        dispatch(socketUpdatePageRefresh(item.previousItemData, true));

        const { leaseType, id, status } = item.previousItemData;
        const event = new CustomEvent(AGENCY_STREAM_MESSAGE_RECEIVED, {
            detail: {
                type: 'agreement-deleted',
                agreementType: leaseType,
                agreementId: id,
                status
            }
        });

        document.dispatchEvent(event);
    });

    useEffect(() => {
        // After save team member selection, update query key to refetch the page
        if (isTeamMemberSelectionSuccess) {
            setSavedTeamMembers([...selected]);
        }
    }, [isTeamMemberSelectionSuccess]);

    useEffect(() => {
        dispatch(connections.getConnectionCompanies());
        dispatch(agency.getAllTeamMembers());
        /**
         * Get agent signature on load
         */
        if (userInfo.id) {
            dispatch(getAgentSignature(userInfo.id));
            setSelected(userInfo.savedTeamMemberSelection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userInfo.id]);

    useEffect(() => {
        dispatch(lease.setCurrentLeaseType(leaseType));
    }, [dispatch, leaseType]);

    /**
     * Load all leases if leaseType changes or team member selection changes
     */
    useEffect(() => {
        if (!isEmpty(leaseType) && typeof savedTeamMemberSelection !== 'undefined' && !useDashboardV2) {
            const newPageNav = getPaginationByStatus(leaseStatus);
            loadAllLeases(leaseType, newPageNav, savedTeamMemberSelection);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseType, savedTeamMemberSelection, useDashboardV2]);

    /**
     * Load specific leases when status is changed
     */
    useEffect(() => {
        if (leaseStatus && typeof savedTeamMemberSelection !== 'undefined' && !useDashboardV2) {
            let newPageNav = getPaginationByStatus(leaseStatus, pageNav);
            loadLeasesByStatus(leaseType, leaseStatus, savedTeamMemberSelection, newPageNav.page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaseStatus]);

    /**
     * If refresh page was requested from the socket
     * Action is taken here
     */
    useEffect(() => {
        if (refreshPage) {
            // don't do anything if user is looked at a different leaseType
            if (socketUpdateDetails.leaseType !== leaseTypeFromDB) {
                dispatch(resetRefreshPage());
                return;
            }
            let agreementOnPage = false;

            if (agreementList && agreementList[leaseStatus]) {
                agreementOnPage = agreementList[leaseStatus].some(agreement => {
                    return agreement.id === socketUpdateDetails.id;
                });
            }

            /**
             * User is looking at status of agreement that was changed OR user has agreement that was changed on the page
             */
            if (mapLeaseStatusToCategory(socketUpdateDetails.status) === leaseStatus || agreementOnPage) {
                loadLeasesByStatus(
                    leaseType,
                    leaseStatus,
                    savedTeamMemberSelection,
                    pageNav[mapLeaseStatusToCategory(leaseStatus)]
                        ? pageNav[mapLeaseStatusToCategory(leaseStatus)].page
                        : 1
                );
            }

            // reset refreshPage so it is executed next time page refresh comes in
            dispatch(resetRefreshPage());
            return () => {
                if (refreshPage) {
                    dispatch(resetRefreshPage());
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshPage]);
    /**
     * If refresh page was requested from the socket
     * Action is taken here
     */
    useEffect(() => {
        if (forceRefreshPage) {
            loadLeasesByStatus(
                leaseType,
                leaseStatus,
                savedTeamMemberSelection,
                pageNav[mapLeaseStatusToCategory(leaseStatus)] ? pageNav[mapLeaseStatusToCategory(leaseStatus)].page : 1
            );

            // reset refreshPage so it is executed next time page refresh comes in
            dispatch(resetRefreshPage());
            return () => {
                if (refreshPage) {
                    dispatch(resetRefreshPage());
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefreshPage]);

    const loadAllLeases = (leaseType, allPageNav, teamMembersViewing = []) => {
        forEach(dashboard.availableAgreementStatuses, status => {
            let newPageNav = getPaginationByStatus(status, allPageNav);
            const clearList = true;
            if (isEmpty(teamMembersViewing)) {
                dispatch(
                    dashboard.getAgreementList(
                        leaseType,
                        newPageNav.page,
                        newPageNav.recordsPerPage,
                        status,
                        undefined,
                        clearList
                    )
                );
            } else {
                dispatch(
                    dashboard.getAgreementList(
                        leaseType,
                        newPageNav.page,
                        newPageNav.recordsPerPage,
                        status,
                        teamMembersViewing,
                        clearList
                    )
                );
            }
        });
    };

    const loadLeasesByStatus = (
        leaseType,
        leaseStatus,
        teamMembersViewing = [],
        page = defaultPage,
        recordsPerPage = defaultRecordsPerPage,
        newSortOptions = {}
    ) => {
        const clearList = false;
        if (isEmpty(teamMembersViewing)) {
            dispatch(
                dashboard.getAgreementList(
                    leaseType,
                    page,
                    recordsPerPage,
                    leaseStatus,
                    undefined,
                    clearList,
                    newSortOptions
                )
            );
        } else {
            dispatch(
                dashboard.getAgreementList(
                    leaseType,
                    page,
                    recordsPerPage,
                    leaseStatus,
                    teamMembersViewing,
                    clearList,
                    newSortOptions
                )
            );
        }
    };

    const getPaginationByStatus = (status, allPagination = pageNav) => {
        return status in allPagination
            ? allPagination[status]
            : { page: defaultPage, recordsPerPage: defaultRecordsPerPage };
    };
    const getSortOptionsByStatus = (status, allSortOptions = sortOptions) => {
        return status in allSortOptions ? allSortOptions[status] : {};
    };

    const changePage = page => {
        const newPageNav = getPaginationByStatus(leaseStatus);
        const newSortOptions = getSortOptionsByStatus(leaseStatus);
        loadLeasesByStatus(
            leaseType,
            leaseStatus,
            savedTeamMemberSelection,
            page,
            newPageNav.recordsPerPage,
            newSortOptions
        );
    };

    const handleMultiSelection = teamMembers => {
        setSelected(teamMembers);

        if (!useDashboardV2) {
            let newPageNav = cloneDeep(pageNav);
            newPageNav[leaseStatus].page = 1;

            dispatch(dashboard.updatePageNav(newPageNav));
            dispatch(updateTeamMemberViewingState(teamMembers)).then(() => {
                dispatch(updateTeamMemberViewing(teamMembers));
            });
        } else {
            dispatch(updateTeamMemberViewing(teamMembers));
        }
    };

    const customSelectionBoxHeader = selected => {
        let teamMemberNumber = selected.length;
        if (teamMemberNumber > 1) {
            return teamMemberNumber + ' team members selected';
        } else if (teamMemberNumber > 0) {
            return teamMemberNumber + ' team member selected';
        }
        return 'Select team members';
    };

    /**
     * Open agreement details modal window.
     * @param {object} agreementInfo - lease agreement model to be shown via using hook.
     */
    const showModalInfoAgreement = agreementInfo => {
        openModalInfoAgreement(agreementInfo, leaseType);
    };

    /**
     * Check whether the user has permission to view others' leases
     *
     * @return {boolean}
     */
    const canViewOthersAgreements = () => {
        const hasPermissionTo = userInfo.hasPermissionTo;
        if (isAdmin(userInfo.role)) {
            return true;
        } else {
            return !isEmpty(hasPermissionTo);
        }
    };

    /**
     * get the other agents' names that the user has permission to
     *
     * @return {array}
     */
    const getAgentNamesByIds = () => {
        const hasPermissionTo = userInfo.hasPermissionTo;

        let permittedAgentsList;

        if (isAdmin(userInfo.role)) {
            permittedAgentsList = team;
        } else {
            permittedAgentsList = filter(team, teamMember => {
                return includes(hasPermissionTo, teamMember.id);
            });
            //Add the loggedIn agent to the list
            if (team && team.length > 0) {
                const member = team.find(currentMember => currentMember.id === userInfo.id);
                if (member) {
                    permittedAgentsList.unshift(member);
                }
            }
        }

        return permittedAgentsList.map(value => {
            return {
                value: value.id,
                label: value.firstName + ' ' + value.secondName
            };
        });
    };

    const options = getAgentNamesByIds();
    const enableTeamViewing = canViewOthersAgreements();
    let newPageNav = getPaginationByStatus(leaseStatus);
    let pagination = (
        <div className="wrapper-pagination">
            <Pagination pageNav={newPageNav} onChange={e => changePage(e)} />
            {newPageNav && newPageNav.recordsPerPage < newPageNav.totalItemsCount ? (
                <p className="pagination-text desktop-only">
                    Page {newPageNav.page} of {Math.ceil(newPageNav.totalItemsCount / newPageNav.recordsPerPage)}
                </p>
            ) : (
                <p />
            )}
        </div>
    );

    const getIconForAgreementType = leaseType => {
        switch (leaseType) {
            case LEASE_TYPE_RESIDENTIAL:
                return <ResidentialTenancyIcon />;
            case LEASE_TYPE_SALES:
                return <SalesIcon />;
            case LEASE_TYPE_PROPERTY_MANAGEMENT:
                return <PropertyManagementIcon />;
            case LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT:
                return <PropertyManagementIcon />;
            case LEASE_TYPE_COMMERCIAL_LEASE:
                return <CommercialLeaseIcon />;
            case LEASE_TYPE_HOLIDAY_LETTING:
                return <Icon icon={Icons.HOLIDAY_HOUSE} />;
        }
    };

    const getDisplayNameForAgreementType = leaseType => {
        switch (leaseType) {
            case LEASE_TYPE_RESIDENTIAL:
                return 'Residential Tenancy Agreements';
            case LEASE_TYPE_SALES:
                return 'Sales Agreements';
            case LEASE_TYPE_PROPERTY_MANAGEMENT:
                return 'Property Management Agreements';
            case LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT:
                return 'Commercial Property Management Agreements';
            case LEASE_TYPE_COMMERCIAL_LEASE:
                return 'Commercial Leases';
            case LEASE_TYPE_HOLIDAY_LETTING:
                return 'Holiday Letting Agreements';
        }
    };

    const clearExpiredRenewalAgreements = async agency_id => {
        setIsClearRenewalbuttonDisabled(true);
        dispatch(changeIsPendingStatus(true));
        axios.post(`api/renewal/${agency_id}/move-expired-renewals-to-complete`).finally(() => {
            dispatch(changeIsPendingStatus(false));
            dispatch(refreshPageOnUpdate());
            setIsClearRenewalbuttonDisabled(false);
        });
    };

    // popup to clear expired renewals
    const confirmClearExpiredAgreements = () => {
        confirmAlert({
            title: '',
            message: 'This will move all expired renewals back to the complete tab.',
            buttons: [
                {
                    label: 'Cancel',
                    onClick: () => {}
                },
                {
                    label: 'Clear expired renewals',
                    onClick: () => clearExpiredRenewalAgreements(user.agency.id)
                },
                {
                    className: 'close close-modal',
                    label: <CloseSharp />,
                    onClick: () => {}
                }
            ]
        });
    };

    function mapToV2Status(status) {
        if (status === dashboard.LEASE_STATUS_ARCHIVED) {
            return dashboard.LEASE_STATUS_COMPLETE;
        } else {
            return status;
        }
    }

    function getV2Dashboard(leaseType) {
        switch (leaseType) {
            case LEASE_TYPE_HOLIDAY_LETTING:
                return (
                    <HolidayLettingDashboard
                        agencyId={agencyId}
                        status={leaseStatus}
                        onStatusChanged={newStatus => history.push(`/dashboard/agreements/${leaseType}/${newStatus}`)}
                        queryKeys={savedTeamMembers}
                        isLoading={isTeamMemberSelectionPending}
                        userRole={userRole}
                    />
                );
            case LEASE_TYPE_SALES:
                return (
                    <SalesDashboard
                        agencyId={agencyId}
                        status={mapToV2Status(leaseStatus)}
                        onStatusChanged={newStatus => history.push(`/dashboard/agreements/${leaseType}/${newStatus}`)}
                        queryKeys={savedTeamMembers}
                        isLoading={isTeamMemberSelectionPending}
                        userRole={userRole}
                        performLegacyAction={performLegacyAction}
                    />
                );
            case LEASE_TYPE_PROPERTY_MANAGEMENT:
                return (
                    <PropertyManagementDashboard
                        agencyId={agencyId}
                        status={mapToV2Status(leaseStatus)}
                        onStatusChanged={newStatus => history.push(`/dashboard/agreements/${leaseType}/${newStatus}`)}
                        queryKeys={savedTeamMembers}
                        isLoading={isTeamMemberSelectionPending}
                        userRole={userRole}
                        performLegacyAction={performLegacyAction}
                    />
                );
            case LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT:
                return (
                    <CommercialPropertyManagementDashboard
                        agencyId={agencyId}
                        status={leaseStatus}
                        onStatusChanged={newStatus => history.push(`/dashboard/agreements/${leaseType}/${newStatus}`)}
                        queryKeys={savedTeamMembers}
                        isLoading={isTeamMemberSelectionPending}
                        userRole={userRole}
                    />
                );
            default:
                return null;
        }
    }

    return useDashboardV2 ? (
        <DashboardV2
            header={
                <DashboardHeader
                    icon={getIconForAgreementType(leaseType)}
                    title={getDisplayNameForAgreementType(leaseType)}
                    selectAgent={
                        enableTeamViewing ? (
                            <MultiSelect
                                options={options}
                                selected={selected || []}
                                onSelectedChanged={handleMultiSelection}
                                selectAllLabel="All members"
                                valueRenderer={customSelectionBoxHeader}
                            />
                        ) : undefined
                    }
                    manageTemplates={
                        (isLeaseTypePm(leaseType) || isLeaseTypeCommercialPm(leaseType)) && isAdmin(userInfo.role) ? (
                            <React.Fragment>
                                <Link
                                    className="special-link templates-link"
                                    to={`${routes.ROUTE_AGREEMENTS_TEMPLATES}?tab=1`}
                                >
                                    <span>Manage Templates</span>
                                </Link>
                                <div className="templates-icon">
                                    <TemplatesToolTip />
                                </div>
                            </React.Fragment>
                        ) : undefined
                    }
                />
            }
            body={getV2Dashboard(leaseType)}
        />
    ) : (
        <Dashboard
            header={
                <DashboardHeader
                    icon={getIconForAgreementType(leaseType)}
                    title={getDisplayNameForAgreementType(leaseType)}
                    selectAgent={
                        enableTeamViewing ? (
                            <MultiSelect
                                options={options}
                                selected={selected || []}
                                onSelectedChanged={handleMultiSelection}
                                selectAllLabel="All members"
                                valueRenderer={customSelectionBoxHeader}
                            />
                        ) : undefined
                    }
                    manageTemplates={
                        isLeaseTypePm(leaseType) && isAdmin(userInfo.role) ? (
                            <React.Fragment>
                                <Link
                                    className="special-link templates-link"
                                    to={`${routes.ROUTE_AGREEMENTS_TEMPLATES}?tab=1`}
                                >
                                    <span>Manage Templates</span>
                                </Link>
                                <div className="templates-icon">
                                    <TemplatesToolTip />
                                </div>
                            </React.Fragment>
                        ) : undefined
                    }
                />
            }
            tabBarProps={{
                tabBar: <AgreementStatusToolbar leaseType={leaseType} leaseStatus={leaseStatus} pagination={pageNav} />,
                isPending: isPending
            }}
            body={
                <React.Fragment>
                    {hasInitialAgreementListRequested && hasAgreements && (
                        <React.Fragment>
                            {LEASE_TYPE_RESIDENTIAL &&
                                leaseStatus === dashboard.LEASE_STATUS_AWAITING_RENEWAL &&
                                agreementList[dashboard.LEASE_STATUS_AWAITING_RENEWAL]?.length > 0 &&
                                isUserAdmin && (
                                    <Button
                                        link
                                        disabled={isClearRenewalbuttonDisabled}
                                        className={styles.clearExpireButton}
                                        onClick={confirmClearExpiredAgreements}
                                    >
                                        Clear Expired Agreements
                                    </Button>
                                )}
                            <AgreementTable
                                leaseType={leaseType}
                                leaseStatus={leaseStatus}
                                agreementList={agreementList[leaseStatus]}
                                showModalInfoAgreement={agreementInfo => showModalInfoAgreement(agreementInfo)}
                            />
                        </React.Fragment>
                    )}

                    {hasInitialAgreementListRequested && !hasAgreements && !isPending && (
                        <EmptyState type={leaseType} />
                    )}
                </React.Fragment>
            }
            pagination={pagination}
        />
    );
};

AgreementList.propTypes = {
    isOpenAgreementInfoModal: PropTypes.bool,
    isOpenLeaseAgreementForm: PropTypes.bool,
    isEditLeaseAgreementForm: PropTypes.bool,
    pageNav: PropTypes.object
};

export default memo(AgreementList);
